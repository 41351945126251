import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Config } from '../../config/config'

import Layout from '../../layouts'

import ResultsSidenav from "../../partials/results-sidenav"

import '../../scss/list.scss'

import image from '../../assets/images/PT6A1326 small.jpg'

const DemoPage = () => {
  return (
    <Layout>
      <div className="list-page results">

        <Helmet>
          <title>DigiWells – Demonstrations</title>
          <meta property="og:title" content="DigiWells – Demonstrations" />
          <meta name="description" content="DigiWells demonstrations." />
          <meta property="og:description" content="DigiWells demonstrations." />
          {
            /*
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            */
          }
        </Helmet>

        <div className="container">
          <ResultsSidenav />

          <div className="container-7">
            <h1 className="page-title">Demonstrations</h1>

            <div className="page-intro">
              The links to interactive demonstrations and demo videos would be available on this page.
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default DemoPage
